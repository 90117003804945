import {html, nothing} from 'lit';

const getIcon = (props) => {
  const pos = props.trailingIcon ? 'end' : 'start';
  return html`
  <md-icon slot="${pos}">${props.icon}</md-icon>
`;
};


const getImage = (props) => {
  const pos = props.trailingIcon ? 'end' : 'start';
  return html`
  <img slot="start" src="${props.image}" />
`;
};

const getSupportingText = (props) => {
  return html`
  <div slot="supporting-text">${props.supportingText}</div>
`;
};

export const getListItem = (props)=> {
  if (!props.divider) {
    return html `
    <md-list-item
      id=${props.id || nothing}
      name=${props.name || nothing}
      class=${props.classes || nothing}
      href=${props.href || nothing} 
      target=${props.target || nothing}
      type=${props.type || nothing}
      tabindex="1"
      ?disabled=${props.disabled}>
      <div slot="headline">${props.label}</div>
      ${props.icon ? getIcon(props) : nothing}
      ${props.image ? getImage(props) : nothing}
      ${props.supportingText ? getSupportingText(props) : nothing}
    </md-list-item>
  `;
  } else {
    return html ` <md-divider  class=${props.classes || nothing}></md-divider>`;
  }
};
