// license MIT
// Copyright 2021 Fourmortals Productions Inc.
export const strings = {
  ADMIN_ITEM: 'admin',
  ADMIN_TOGGLE: 'admin-toggle',
  AGENT_ITEM: 'agent',
  AGENT_TOGGLE: 'agent-toggle',
  HIDDEN: 'hidden',
  TECH_ITEM: 'tech',
};
